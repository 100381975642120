import React from "react";
import Layout from "components/layout";
import Style from "styles/contact.module.scss";
import SEO from "components/seo";

const Service = () => (
  <Layout>
    <SEO title="完了|contact" path="contact/done" />
    <div className={Style.container}>
      <div className={Style.ctIntro}>
        <div className={Style.ctIntro__inner}>
          <h1 className={Style.ctIntro__title}>CONTACT</h1>
        </div>
      </div>
      <div className={Style.ctDone}>
        <h1>お問い合わせありがとうございます</h1>
        <p>
          ご記入していただいた内容は無事に送信されました。
          <br />
          お問い合わせ内容の確認後、担当者よりご連絡させていただきます。
          <br />
          また、確認のため自動返信メールをお送りしております。
        </p>
      </div>
    </div>
  </Layout>
);

export default Service;
